@import '../../../../scss/theme-bootstrap';

.gnav-section-formatter {
  @include breakpoint($landscape-up) {
    background-color: $color-lighter-gray-2;
  }
  &__content-wrapper {
    width: 100%;
    @include breakpoint($landscape-up) {
      background-color: $color-lighter-gray-2;
      display: block;
      max-width: $max-width-cr21;
      max-height: $gnav-content-max-height;
      overflow-y: auto;
      position: fixed;
      height: 0;
      .gnav-section-formatter[aria-expanded='true'] & {
        height: 100%;
      }
    }
    .gnav-section-formatter--bottom-tout-description & {
      .gnav-tout {
        @include breakpoint($landscape-up) {
          &__inner {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 0;
          }
          &__image {
            width: 100%;
            height: 172px;
            .mantle-media-asset {
              display: flex;
              align-content: flex-end;
            }
            picture {
              justify-content: center;
            }
          }
          &__description {
            text-align: center;
            padding: 0;
            width: 100%;
          }
          &__headline {
            font-size: 12px;
            color: $color-black;
          }
        }
      }
    }
  }
  &__gnav-links-wrapper {
    display: flex;
    flex-direction: column;
    height: 0;
    order: 2;
    width: 100%;
    @include breakpoint($landscape-up) {
      justify-content: space-between;
      padding-bottom: 12px;
      flex-direction: row;
      max-width: 100%;
      position: relative;
      order: unset;
    }
    .gnav-section-formatter[aria-expanded='true'] & {
      height: auto;
      @include breakpoint($landscape-up) {
        height: 100%;
      }
    }
    // default expanded layout is grid-1 from cms selection
    // .gnav-section-formatter--grid-1 (5 even columns)
    > div {
      padding: 0;
      flex: 0 20%;
      justify-content: space-between;
      .gnav-section-formatter--bottom-tout-description & {
        justify-content: center;
      }
    }
    > .gnav-links:not(.gnav-links--columns-break) {
      flex: 0 12.5%;
    }
  }
}
